import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { dribbble } from 'react-icons-kit/fa/dribbble';
import { googlePlus } from 'react-icons-kit/fa/googlePlus';
import { skype } from 'react-icons-kit/fa/skype';
import { twitter } from 'react-icons-kit/fa/twitter';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import flogo from 'common/assets/image/interior/radiance-logo.png';


import FooterWrapper, {
  List,
  ListItem,
  SocialList,
} from './footer.style';

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: '#',
  },
  {
    id: 2,
    icon: <Icon icon={dribbble} />,
    name: 'dribbble',
    link: '#',
  },
  {
    id: 3,
    icon: <Icon icon={googlePlus} />,
    name: 'googlePlus',
    link: '#',
  },
  {
    id: 4,
    icon: <Icon icon={skype} />,
    name: 'skype',
    link: '#',
  },
  {
    id: 5,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: '#',
  },
];

const Footer = ({ row, col, colOne, colTwo }) => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        footerData {
          mail
          phone
          menuWidgets {
            id
            title
            menu {
              id
              text
              link
            }
          }
        }
      }
    }
  `);
  const { mail, phone, menuWidgets } = data.interiorJson.footerData;

  return (
    <Fragment>
      
      <FooterWrapper className='mainFootersite'>
        <Container width="1330px" className="footerwrapscol">
            <div className='footerLeftarea'>
              <Logo
                  className="logo"
                  href="/"
                  logoSrc={flogo}
                  title="Interior"
                />
                <div className='contactiInfo'>
                  <p>3rd Floor, A-66, Rajouri Garden,<br />New Delhi, Delhi 110027</p>
                  <p>+91-99585-85649</p>
                  <p>contact@radiancehairstudio.com</p>
                </div>
            </div>
            <div className='footerRightarea'>
                <div className='footerInsideright'>
                  <div className='colWraps'>
                      <h3>Quick Links</h3>
                      <ul>
                        <li><a href="https://www.radiancehairstudio.com/about.php">About Us</a></li>
                        <li><a href="https://www.radiancehairstudio.com/services.php">Services</a></li>
                        <li><a href="https://www.radiancehairstudio.com/wigs-for-men.php">Wigs For Mens</a></li>
                        <li><a href="https://radiancehairstudio.com/blog/">Latest News</a></li>
                        <li><a href="#bookAppointment">Book Appointment</a></li>
                      </ul>
                  </div>
                  <div className='colWraps'>
                    <h3>Services</h3>
                      <ul>
                        <li><a href="https://www.radiancehairstudio.com/hair-replacement-for-men.php">Hair Replacement System</a></li>
                        <li><a href="https://www.radiancehairstudio.com/hair-patch-mens.php">Hair Patch</a></li>
                        <li><a href="https://www.radiancehairstudio.com/wigs-for-men.php">Hair Wigs</a></li>
                        <li><a href="https://www.radiancehairstudio.com/hair-bonding-for-men.php">Hair Bonding</a></li>
                        <li><a href="https://www.radiancehairstudio.com/hair-extensions-for-men.php">Hair Extension </a></li>
                      </ul>
                  </div>
                  <div className='colWraps'>
                      <h3>Office Hours</h3>
                      <p>Visiting Hours<br />10am-8pm Everyday</p><br />
                      <p>Contact Hours<br />9am-9pm Everyday</p>
                  </div>
                </div>
                 </div>
        </Container>
        <Container width="1330px" className="footercpyr">
            <p>Copyright © 2023 Radiance Hair Studio. All Right Reserved.</p>
            <p>Designed by Immersive Infotech.</p>
        </Container>
      </FooterWrapper>











      <FooterWrapper style={{ display: 'none' }}>
        <Container width="1330px">
          <Box className="row" {...row}>
            <Box className="col-one" {...colOne}>
              <Logo
                className="logo"
                href="/"
                logoSrc={flogo}
                title="Interior"
              />
              <Text className="text" content={mail} />
              <Text className="text" content={phone} />
              <SocialList>
                {socialLinks.map((item) => (
                  <li className={item.name} key={`link-key${item.id}`}>
                    <a aria-label={item.name} href={item.link}>
                      {item.icon}
                    </a>
                  </li>
                ))}
              </SocialList>
            </Box>
            {/* End of logo column */}

            <Box className="col-two" {...colTwo}>
              {menuWidgets.map((widget) => (
                <Box
                  className="col"
                  {...col}
                  key={`footer_widget-key${widget.id}`}
                >
                  <Heading
                    className="widget_title"
                    as="h3"
                    content={widget.title}
                  />
                  <List>
                    {widget.menu.map((item) => (
                      <ListItem key={`list__item-${item.id}`}>
                        <a href={item.link}>{item.text}</a>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Box>
            {/* End of List column */}
          </Box>
          {/* End of widgets row */}
          <Box className="row copyright" {...row}>
            <Text className="text" content="copyright 2019 @RedQ" />
            <p>
              Built & designed By Immersive Infotech.
            </p>
          </Box>
          {/* End of copyright row */}
        </Container>
      </FooterWrapper>
    </Fragment>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '28%'],
    mt: [0, '13px', '0'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '72%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default Footer;
